.termsandconditions{
    padding-top: 130px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.innertermsandconditions{
    width: 800px;
    margin-bottom: 100px;
}

.headingterms{
    text-align: center;
    font-size: 50px;
    margin-bottom: 90px;
}

.innertermsandconditions  h3{
    margin-top: 40px;
    margin-bottom: 40px;
    font-size: 24px;
}

.innertermsandconditions  p{
    font-size: 18px;
    line-height: 30px;
}

.innertermsandconditions ul{
    margin-left: 40px;
}

.innertermsandconditions  li{
    font-size: 18px;
    line-height: 30px;
}

@media only screen and (max-width:850px) {
    .innertermsandconditions{
        width: 90%;
    }
}