.sharecontainer{
    width: 600px;
    height: 300px;
    background-color: white;
    border-radius: 20px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    position: relative;
}

@media only screen and (max-width:650px) {
    .sharecontainer{
        width: 90%;
    }
}

.sharesocialcont{
    width: 100%;
    display: flex;
    flex-direction: column;
}

.sharesocialcont span{
    font-size: 30px;
}

.shareicons{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 20px;
}



.ctoclipboard{
    width: 100%;
    padding: 10px;
    border: 1px solid black;
    display: flex;
    justify-content: space-between;
}

@media only screen and (max-width:600px) {
    .ctoclipboard{
        display: none;
    }
}

.sharemodelcloser{
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    position:absolute;
    top: -10px;
    right: -10px;
    background-color: white;    
    border-radius: 50%;
    cursor: pointer;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}