.gototopbtn{
    height: 45px;
    width: 45px;
    border-radius: 50%;
    background-color: #435cff;
    display: none;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 100;
    transition: all 1s ease;
    cursor: pointer;
}

.gototopbtnshow{
    display: flex;

}

