.backdropwelcome{
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(120, 120, 120, 0.7);
    display: flex;
    /* align-items: center; */
    justify-content: center;
    z-index: 100;
}

.welcomepopup{
    padding: 25px;
    width: 500px;
    height: 500px;
    background-color: white;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    text-align: center;
    border-radius: 20px;
    position: relative;
    margin-top: 40px;
}

.welcomebtn{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 30px;
    background: #435CFF;
    border-radius: 100px;
    border: none;
    width: 300px;
    height: 72px;
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    text-align: center;
    color: #FFFFFF;
    cursor:pointer;
    
}

@media only screen and (max-width:538px)
{
    .welcomepopup{
    width: 300px;
    height: 400px;
    }

    .welcomepopup > img{
        height: auto;
        width: 150px;
    }

    .welcomebtn{
        width: 200px;
        padding: 0px 0px !important;
        height: 40px;

    }
}