.loggedinnav{
    background-color: transparent;
    width: 100%;
    height: 100px;
    padding-left: 100px;
    padding-right: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: 0px;
    z-index: 10;
}

.searchbar{
    position: relative;
}

.searchbar > input {
    width: 261px;
    height: 40px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 17px;
    background: #FAFAFA;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
    border-radius: 30px;
    border: none;
    
}

.searchbar > input:focus{
    outline: none;
    border: none;
}

.searchicon{
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 50;
    font-size: 20px;
    cursor: pointer;
}

.loggedinnav > span
{
    font-weight: 500;
    font-size: 20px;
    cursor: pointer;
}

.loggedinnav > h1
{
 
    cursor: pointer;
}

.navaccoutholder{
    height: 40px;
    cursor: pointer;
    background-color: white;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 30px;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
    padding-left: 10px;
    padding-right: 10px;
    position: relative;

}

.navaccoutholder > img{
    height: 30px;
    width: 30px;
    border-radius: 50%;
}

.navaccoutholder > span{
    margin-left: 20px;
    margin-right: 20px;
}

.bandacont{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 350px;
}

.bandacont > span{
    font-weight: 600;
    color: #435CFF;
    font-size: 20px;
    cursor: pointer;
}


.accountdropdown{
    position: absolute;
    width: 200px;
    height: 150px;
    background-color: white; 
    top: 50px;
    right: -20px;
    border-radius: 20px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 20px;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    transition: all 5s ease;
    display: none;
}

.accountdropdownitems{
    display: flex;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 7px;
    padding-left: 10px;

}

.accountdropdownitems >img {
    margin-right: 20px;
    height: 20px;
    width: auto;
}

.accountdropdowndisplay{
    display: flex;
}

.accountdropdownitems:hover{
    background-color: #f3f3f3;
}

.mockprofileimg{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    color: white;
    padding: 5px;
    border-radius: 50%;
    background-color: black;
}

@media only screen and (max-width:1090px)  {
    .loggedinnav{
        padding-left: 30px;
        padding-right: 30px;
    }
}


@media only screen and (max-width:700px)  {
    .loggedinnav{
        padding-left: 20px;
        padding-right: 20px;
    }

    .bandacont{
        width: 240px;
        justify-content: flex-end;
    }

    .navaccoutholder > img{
        width: 20px;
        height: 20px;
    }
    .loggedinnav > h1 {
        font-size: 26px;
    }

    .bandacont > span {
        display: none;
        font-size: 16px;
    }

    .navaccoutholder > span {
        margin-left: 10px;
        margin-right: 10px;
        font-size: 14px;
    }

    .navaccoutholder
    {
        padding-left: 5px;
        padding-right: 5px;
        height: 32px;
    }
}