.maxbeamcardcont{
    display: flex;
    width: 500px;
    height: 250px;
    background-color: white;
    box-shadow: 0px 20px 60px rgba(128, 144, 155, 0.2);
    border-radius: 20px;
    margin: 10px;
    margin-bottom: 60px;
    position: relative;
    
}

@media only screen and (max-width:537px)
{
    .maxbeamcardcont{
        width: 95vw;
    }
}

.maxbeamdata > button{
    width: 120px;
    height: 41.5px;
    background: #F7CD61;
    border-radius: 30px;
    border: none;
    font-weight: 600;
    /* font-size: 18px; */
    cursor: pointer;
}


.maxbeamdata{
    /* padding: 40px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    width: 75%;
}

.maxcardimg{
    
    width: 35%;
}
.maxcardimg img{
    height: 100%;
    width: 100%;
    border-radius: 20px 0px 0px 20px;
}

.maxbeamdata > p{
    margin: 20px;
}

.favcard3dots{
    position: absolute;
    bottom: 20px;
    right: 20px;
    cursor: pointer;
}

.favcarddrop{
    height: 110px;
    justify-content: space-evenly;
}