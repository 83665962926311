.aboutuspage{
   display: flex;
   flex-direction: column;
   width: 100%;
    overflow-x: hidden;
}

.abtus1{
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.abtus1 > h1{
    font-size: 60px;
    margin-top: 50px;
}

.abtus1 > img{
    width: 450px;
    height: auto;
}

.yellowicon{
    color: #F7CD61;
}

@media only screen and (max-width:835px) {
    .abtus1 > h1{
        font-size: 50px;
        margin-top: 50px;
    }

    .abtus1 > img{
        width: 400px;
    }
}

@media only screen and (max-width:551px) {
    .abtus1 > h1{
        font-size: 32px;
        margin-top: 50px;
    }

    .abtus1 > img{
        width: 400px;
    }
}

.abt1bg{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("../../assets/aboutusimg.png");
    width: 80%;
    height: 80%;
    margin-top: 100px;
    border-radius: 20px;
    /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
}

.abtush1{
    position: absolute;
    top: 0;
    padding: 20px 40px;
    background-color: #F7CD61;
    font-size: 30px;
    border-radius: 0px 0px 10px 10px;
}

.abt1bg > h1{
    font-size: 70px;
    letter-spacing: 5px;
    color: white;
}

.abtus2{
    height: 800px;
    width: 100%;
    background-image: url("../../assets/aboutussec2bg.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 100px;
    padding-bottom: 100px;

    margin-top: 50px;
}

.abtsec2inner{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.abtsec2pdiv{
    width: 700px;
    height: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    color: rgb(0, 0, 0);
}

.abtsec2pdiv > p{
    font-size: 20px;
    line-height: 40px;
}

.abtsec2pdiv > p > strong{
    font-size: 24px;
}

.abtusdivider{
    background-color: white;
    height: 3px;
    width: 150px;
    margin-top: 30px;
}

.abtus2inner2{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.abtus2inner2 > div{
   margin-left: 60px;
}

.abtus2inner2 >div > p{
    color: white;
    margin: 20px;
    font-size: 24px;
    width: 550px;
}

@media only screen and (max-width:900px)
{
    .abtus2{
        height: 100%;
    }

    .abtsec2pdiv{
        width: 90%;
        height: none;
        margin-top: 40px;
    }

    .abtsec2pdiv > p{
        font-size: 18px;
        line-height: 50px;
        margin-top: 20px;
    }
    
    .abtsec2pdiv > p > strong{
        font-size: 20px;
    }
}

.abtussec3{
    width: 100%;
    height: 900px;
    display: flex;
    align-items: flex-start;

    /* background-color: #d76969; */


}


.abtussec31
{
    min-width: 100vw;
    height: 100%;
    padding-top: 100px;
    padding-bottom: 100px;
}

.abtussec3inner{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 80px;
    width: 100%;
    height: 100%;   
 
}

.bgabtus1{
    background-image: url('../../assets/aboutsec3bg.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.bgabtus2{
    background-image: url('../../assets/ourstory2.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.bgabtus3{
    background-image: url('../../assets/ourstory4.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.bgabtus4{
    background-image: url('../../assets/aboutsliderbg4.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.abtussec3inner p{
    width: 60%;
    font-size: 20px;
    line-height: 50px;
}

.abtussec3inner img{
    margin-bottom: 20px;
}

.scrolldiv{
    position: relative;
    transition: right 0.3s ease;

}

.arrowicon{
    font-size: 36px;
}

.scrollerarrow{
    position: absolute;
    width: 100%;
    display: flex;
    padding-left: 80px;
    padding-right: 80px;
    justify-content: flex-end;
    height: 70px;
    align-items: center;
    top: 50%;
    transform: translateY(-50%);

}

@media only screen and (max-width:1280px) {
    .abtussec3inner p{
        line-height: 40px !important;
    font-size: 19px;

    }
}

@media only screen and (max-width:900px) {
    .abtussec3inner {
        padding: 20px;
    }

    .scrollerarrow{
        padding-left: 30px;
        padding-right: 30px;
    }

    .abtussec3inner p{
        width: 80%;
    }

    .arrowicon{
        font-size: 20px;
    }

    .abtussec3inner > img{
        width: 300px;
        height: auto;
        /* margin-top: 190px; */
    }
}   

@media screen and (max-width:478px) {

    .blackcolor > p{
        color: black !important;
    }

    .blackicon{
        color: black !important;
    }

    .abtussec3inner p{
        width: 95%;
        line-height: 32px;
        font-size: 20px;
    }

    .bgabtus4{
        background: none;
    }

    .bgabtus3{
        background: none;
        color: black !important;
    }

    .bgabtus3 .arrowicon{
        color: black;
    }

    .bgabtus2{
        background: none;
    }

    .bgabtus1{
        background: none;
    }

    .scrollerarrow{
        padding-left: 10px;
        padding-right: 10px;
    }

    .abtussec3{
        height: 1000px;
    }
}

.abtus4{
    height: 900px;
    width: 100%;
    background-image: url("../../assets/aboutusteam1.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 100px;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 5;

}

.abtus4info{
    color: white;
    position: absolute;
    font-size: 40px;
    bottom: 100px;
    left: 200px;
}

.abtus4info h1{
    margin: 20px;
}

.abtus4info h3{
    margin: 20px;
}

@media only screen and (max-width:630px) {
    .abtus4info h1{
        font-size: 48px;
        margin-bottom: 0px;
    }
    
    .abtus4info h3{
       font-size: 28px;
       margin-bottom: 0px;
       margin-top: 0px;
    }
}

.abtus4info div{
    margin: 20px;
  
}

.abtus4info > p{
    font-size: 20px;
    margin-top: 10px;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    line-height: 30px;
    color: black;
    margin-left: 20px;
    margin-right: 20px;
}

@media only screen and (max-width:1484px) {
    .abtus4info{
        bottom: 100px;
        left: 50px;
    }
}

@media only screen and (max-width: 1185px) {
    .abtus4info{
       position: static;
       height: 100%;
       display: flex;
       flex-direction: column;
       justify-content: flex-end;
       margin-bottom: 40px;
       margin-left: 20px;
       margin-right: 20px;
    }
}

.abtmission{
    padding-top: 100px;
    width: 100%;
    height: 950px;
    background-color: #ffffff;
}

.abtmissioninner{
    position: relative;
    /* padding: 80px; */
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.abtmissioncircle{
    width: 750px;
    height: 750px;
    border-radius: 50%;
    background-color: #435CFF;
    position: absolute;
    bottom: 0px;
    left: -20px;
    transform: translate(-100px,30px);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 35px;
    padding-left: 80px;
    text-align: center;
    color: white;
    line-height: 80px;
}


.abtvission{
    padding-top: 100px;
    width: 100%;
    height: 950px;
    background-color: #191641;
    z-index: 5;
}

.abtvissioninner{
    position: relative;
    /* padding: 80px; */
    width: 100%;
    height: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
}

.abtvissioncircle{
    width: 750px;
    height: 750px;
    border-radius: 50%;
    background-color: #F7CD61;
    position: absolute;
    bottom: 0px;
    right: -30px;
    transform: translate(80px,30px);
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-size: 22px;
    flex-direction: column;
    color: #191641;
    padding: 120px;
    line-height: 50px;
    padding-right: 170px;
}

.abtusrocket{
    position: absolute;
    left: 560px;
    top: 90px;
    height: 650px;
    width: 650px;
}

.abtusman
{
    position: absolute;
    right: 600px;
    top: 120px;
    height: 600px;
    width: 600px;
}

@media only screen and (max-width:1160px) {
    .abtusrocket{
        height: 550px !important;
        width: 550px !important;  
        left: 500px;
        top: 190px;
    }

    .abtmissioncircle{
        width: 650px;
        height: 650px;
    }

    .abtvissioncircle{
        width: 650px;
        height: 650px;
        font-size: 18px;
        /* padding: 68px; */
    }

    .abtusman
    {
        height: 500px;
        width: 500px;
        right: 500px;
        
    }

}

@media only screen and (max-width:970px) {

    .abtmission{
        height: 700px;
    }

    .abtusrocket{
        height: 450px !important;
        width: 450px !important;  
        left: 400px;
        top: 0px;
    }

    .abtmissioncircle{
        width: 550px;
        height: 550px;
        font-size: 20px;
        line-height: 40px;
    }


    .abtvissioncircle{
        width: 550px;
        height: 550px;
        font-size: 18px;
        line-height: 40px;
    }

    .abtusman
    {
        height: 400px;
        width: 400px;
        right: 300px;
        
    }
    
}

@media only screen and (max-width:780px) {
    .abtusrocket{
        height: 350px !important;
        width: 350px !important;  
        left: 320px;
        top: 200px;
    }

    .abtmissioncircle{
        width: 450px;
        height: 450px;
        font-size: 20px;
        line-height: 40px;
    }

    .abtvissioncircle{
        /* width: 450px;
        height: 450px; */
        font-size: 18px;
        line-height: 40px;
    }


}

@media only screen and (max-width:600px)
{
    .abtmission{
        height: 690px;
        padding-top: 30px;
    }

    .abtvission{
        height: 850px;
    }

    .abtusrocket{
        height: 250px !important;
        width: 250px !important;  
        left: 170px;
        top: 130px;
        transform: rotateZ(15deg);
        animation: mymove alternate;
        animation-duration: 3s;
        animation-iteration-count: infinite;
    }

    .abtmissioncircle{
        width: 400px;
        height: 400px;
        font-size: 18px;
        line-height: 40px;
        margin-left: 20px;
    }

    .abtvissioncircle{
        width: 450px;
        height: 450px;
        font-size: 18px;
        line-height: 40px;
        padding: 80px;
        margin-right: 40px;

    }

    .abtusman
    {
        height: 250px;
        width: 250px;
        right: 120px;
        top: 90px;
    }
}

.abtus5{
    height: 650px;
    width: 100%;
    background-image: url("../../assets/aboutsec5bg.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 100px;
    padding-bottom: 100px;
    z-index: 5;
}

.abtsec5inner{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.abtsec5pdiv{
    width: 850px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    color: black;
}

.abtsec5pdiv > p{
    font-size: 32px;
    line-height: 50px;
}

@media only screen and (max-width:950px) {
    .abtsec5pdiv > p{
        font-size: 28px;
        line-height: 50px;
        padding-left: 25px;
        padding-right: 25px;
    }
}

@media only screen and (max-width:650px) {

    .abtus5{
        height: 450px;

    }

    .abtsec5pdiv > p{
        font-size: 20px;
        line-height: 40px;
        padding-left: 25px;
        padding-right: 25px;
    }
}

/* aboutusrockanimation */

@keyframes mymove {
    0%   {top: 150px;}
    100% {top: 0}
  }