.abtuspopup{
    width: 90%;
    background-color: rgb(255, 255, 255);
    height: 90%;
    border-radius: 20px;
    padding: 50px;
    position: relative;
}

.abtuspopupdata{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.abtuspopupdata > p{
    font-size: 20px;
    line-height: 40px;
}
