@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;1,500;1,700&display=swap');


.alcenter{
  display: flex;
  align-items: center;
  /* justify-content: center; */
  height: 100%;
  width: 100%;
}

*{
  margin: 0px;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Ubuntu', sans-serif;
}

.landingsection{
  /* height: 900px; */
  display: flex;
  scroll-snap-align: start;
  width: 100%;
}



.one{
  height: 100vh;
  display: flex;
  scroll-snap-align: start;
  width: 100%;
}

 /* .landingpage{
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  height: 100vh;
 } */

 .landingpage{
  overflow-x: hidden;
 }
 
 .bgvideo{
  width: 100%;
  position: relative;
  height: 100%;
 }

 .one video{
  height: 100%;
  width: 100%;
  object-fit: cover;
  /* position: relative; */
 }

 .bgvideomask{
  width: 100%;
  position: absolute;
  height: 100%;
  /* background-color: rgb(25, 22, 65, 0); */
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
 }

 .sec1data{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 30px;
  height: 400px;
  justify-content: space-evenly;
  width: 70%;
  /* background-color: rgba(34, 36, 38, 0.5); */

  /* background-color: black; */
 }

 .sec1data h2{
  color: #ffffff;
  font-size: 90px;
  letter-spacing: 3px;
  text-align: center;
  letter-spacing: 0px;
 }

 .sec1data span{
  color: white;
  font-size: 24px;
  text-align: center;
  margin-top: 100px;
 }

 .landingtextoutline{
  font-weight: 400;
  color: transparent !important;
  -webkit-text-stroke: 1.5px #fff;
 }

 .sec1data button{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 30px;
  background: #435CFF;
  border-radius: 100px;
  border: none;
  width: 300px;
  height: 72px;
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  color: #FFFFFF;
  cursor:pointer;
  margin-top: 25px;
 }

 .insertsection{
  background-image: url('./assets/insertsectionbg.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;

 }

 .insertsection > div{

  border-radius: 30px;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: rgb(0, 0, 0, 0.6);
 }

 .sec2bannertitle{
  width: 1300px;
  font-size: 80px;
  text-align: center;
  
 }

 .insertsection > div > span{
  width: 1300px;
  text-align: center;
  margin-top: 55px;
  font-size: 30px;
  line-height: 40px;
 }

.sec2bannertitle{
  font-size: 50px !important;
  text-align: center;
}

 @media only screen and (max-width:1429px) {
  .sec2bannertitle{
    width: 1100px;
    font-size: 70px;
    text-align: center;
   }
  
   .insertsection > div > span{
    width: 1100px;
    text-align: center;
    margin-top: 55px;
    font-size: 28px;
    line-height: 40px;
   }
 }

 @media only screen and (max-width:1430px) {
  .sec2bannertitle{
    font-size: 45px;
   }
 }

 @media only screen and (max-width:1089px) {
  .sec2bannertitle{
    width: 800px;
    font-size: 50px;
    text-align: center;
   }
  
   .insertsection > div > span{
    width: 800px;
    text-align: center;
    margin-top: 55px;
    font-size: 22px;
    line-height: 40px;
   }
 }

 @media only screen and (max-width:880px) {
  .sec2bannertitle{
    width: 500px;
    font-size: 30px;
    text-align: center;
   }
  
   .insertsection > div > span{
    width: 500px;
    text-align: center;
    margin-top: 55px;
    font-size: 18px;
    line-height: 40px;
   }
 }

 @media only screen and (max-width:583px) {
  .insertsection > div{
    padding: 10px;
    margin: 10px;
  }

  .sec2bannertitle{
    width: 90%;
    font-size: 30px !important;
    text-align: center;
   }
  
   .insertsection > div > span{
    width: 90%;
    text-align: center;
    margin-top: 35px;
    font-size: 18px;
    line-height: 28px;
   }
 }


 @media only screen and (max-width:1280px)
 {
  .sec1data{
    height: 250px;
    width: 100%;
  }

  .sec1data h2{
    font-size: 70px;
  }
 }



 @media only screen and (max-width:810px) {
  .sec1data h2{
    font-size: 60px;
  }

  .sec1data span
  {
    font-size: 16px;
  }

 }


 @media only screen and (max-width:810px) {
  .sec1data h2{
    font-size: 50px;
  }
 }


 @media only screen and (max-width:562px) {
  .sec1data{
  height: 200px;
  width: 90%;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  }
  .sec1data h2{
    font-size: 30px;
  }

  .sec1data span
  {
    font-size: 18px;
  }

  .sec1data button{
    height: 60px;
    width: 250px;
    padding: 10px 20px;
    font-size: 17px;

  }

 }

 @media only screen and (max-width:404px) {

  .sec1data h2{
    font-size: 25px;
  }
 }

 /* End of Sec 1 */

 .thethreeqs{
  width: 100%;
  background-color: #191641;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
 }

 .the3qsbox{
  /* height: 540px; */
  width: 480px;
  border-radius: 40px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-between; */
  padding: 40px;
 }

 .the3qsbox > video{
  width: 100%;
  border-radius: 30px;
 }

 .the3qsbox > p{
  margin-top: 20px;
  font-size: 20px;
  line-height: 35px;
 }


 .thethreeqs > h2{
  font-size: 60px;
  margin-top: 120px;
  color: white;
  margin-bottom: 160px;
 }

 .the3qsitem{
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  padding-left: 80px;
  padding-right: 80px;
  margin-top: 0px;
  margin-bottom: 130px;
 }



 .the3qsitemdata{
  display: flex;
  
  justify-content: center;
  width: 50%;
 }

 .the3qsitemdata > p{
  width: 450px;
  font-size: 28px;
  line-height: 70px;
  font-weight: 600;
  color: white;
  margin-right: 20px;
 }

 .the3qsitemdata > span{
  color: white;
  font-weight: 600;
  font-size: 50px;
 }



@media only screen and (max-width:1013px) {

  .the3qsitem{
    flex-direction: column;
    padding: 20px;
    margin-top: 50px;
    margin-bottom: 60px;
  }

  .thethreeqs > h2{
    text-align: center;
    font-size: 50px;
    margin-bottom: 80px;
   }

  .the3qsitemdata{
    width: 90%;
  }

  .the3qsitemdata > p{
  font-size: 24px;
  margin-right: 0px;
  margin-bottom: 70px;
 }

 .the3qsitemdata > span{
  color: white;
  font-weight: 600;
  font-size: 40px;
 }
 
  .the3qsbox > p{
    font-size: 20px;
 }

}

@media only screen and (max-width:600px) {

  .thethreeqs > h2{
    text-align: center;
    font-size: 35px;
    margin-bottom: 40px;
    line-height: 55px;
   }

  .the3qsbox{
    width: 320px;
    padding: 20px;
  }

  .the3qsbox > p{
    font-size: 16px;
 }

 .the3qsitemdata > p{
  font-size: 17px;
  margin-right: 0px;
  margin-bottom: 70px;
  line-height: 45px;
 }

 .the3qsitemdata > span{
  font-size: 30px;
 }
}


 .two
 {
  padding-top: 140px;
  padding-bottom: 20px;
  flex-direction: column;
 }

 .sec2label{
  max-width: 350px;
  width: 30%;
  background-color: #191641;
  padding: 25px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  /* margin-bottom: 30px; */
 }

 .sec2data{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-top: 50px;
  padding-left: 100px;
  padding-right: 100px;
  padding-bottom: 50px;

 }

 .sec2data img{
    width: 100%;
    height: auto;
 }

 .object {
  animation: MoveUpDown 3s linear infinite;
}

@keyframes MoveUpDown {
  0%, 100% {
    padding-top: 0;
  }
  50% {
    padding-top: 80px;
  }
}

 .sec2idiv{
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 550px;
  /* background-color: aliceblue; */
  align-items: center;
  margin-bottom: 50px;
 }

 .sec2idiv h1{
  text-align: center;
  font-size: 32px;
 }

 .sec2contdiv{
  min-height: 230px;
  margin-top: 40px;
  /* height: 60%; */
  background-color: #435CFF;
  width: 100%;
  max-width: 700px;
  border-radius: 20px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
 }

 .sec2contdiv h1{
  text-align: left;
  color: white;
  margin-bottom: 10px;
 }

 .sec2contdiv span{
  color: white;
  line-height: 30px;
 }

 @media only screen and (max-width:1050px)
 {
  .sec2data img{
    width: 90%;
 }
 }

 @media only screen and (max-width:900px)
 {
  .sec2label
  {
    min-width: 250px
  }

  .two
  {
   padding-top: 100px;
   padding-bottom: 20px;
   flex-direction: column;
  }
  .sec2idiv{
    width: 80%;

   }

  .sec2contdiv{
    min-height: 300px;
    margin-top: 40px;
    height: 50%;
    width: 100%;
    
   }

   .sec2idiv > h1
   {
    margin-top: 100px;
    margin-bottom: 20px;
   }
   
 }

 @media only screen and (max-width:800px)
 {
  .sec2idiv{
    width: 95%;

   }
  .sec2data{
    flex-direction: column;
    padding-left: 10px;
    padding-right: 10px;
  }
 }

 
 /* End of Sec 2 */

 .three{
  background-image: url('./assets/landingsec3bg.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  padding-top: 140px;
  flex-direction: column;
  height: 680px;
 }

 .sec3innerdiv{
  display: flex;
  flex-direction: column;

 }

 .sec3innerdiv{
  font-size: 46px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 10%;
 }

 .sec3innerdiv span{
  margin: 10px;
  text-align: center;
 }

 .sec3innerdiv button{
  border: none;
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px 45px;
  background: #435CFF;
  border-radius: 100px;
  font-size: 20px;
  text-align: center;
  color: #FFFFFF;
  cursor: pointer;


 }

 @media only screen and (max-width:800px){
  .three{
    padding-top: 100px;
    height: 750px;
   }

   .sec3innerdiv{
    width: 100%;
    margin-left: 0px;
   }

   
   .sec3innerdiv span{
    font-size: 30px;
    font-weight: 500;
   }
 }

  /* End of Sec 3 */

  .four{
    padding-top: 140px;
    padding-bottom: 80px;
    width: 100%;
    flex-direction: column;
    background-color: #FAFAFA;

  }

  .sec4datadiv{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }

  

 

 

  .sec4cardsdiv
  {
    display: flex;
    width: 85%;
    justify-content: space-evenly;
    margin-bottom: 60px;
  }

  .sec4carddiv{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .sec4cardimgcont{
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    height: 285px;
    width: 285px;
  }

  .sec4carddiv span{
    text-align: center;
    font-weight: 500;
    font-size: 20px;
    width: 230px;

  }

  @media only screen and (max-width:1080px) {
    .sec4cardsdiv{
      flex-direction: column;
    }

    .sec4carddiv{
      margin: 20px;
    }

  

    

   

    .four{
      padding-bottom: 0px;
    }
  }

  

    /* End of Sec 4 */


  .five{
    padding-top: 100px;
    padding-bottom: 100px;
    flex-direction: column;
    width: 100%;
    background-color: #F7CD61;

  }

  .carodiv{
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }

  .caroinner{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: #435CFF; */
  }

  .carosel{
    width: 60%;
  }

  .caroitem{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .caroitem p {
    font-size: 18px;
  }

 

  .caroimg{
    width: 400px !important;
    padding: 20px;
    height: auto !important;
   border-radius: 50% !important;
  }

  .caroitem p{
    width: 50%;
    padding: 20px;
  }

    /* End of Sec 5 */

  .six{
    padding-top: 40px;
    padding-bottom: 100px;
    width: 100%;
    flex-direction: column;
    background-color: #FAFAFA;
  }

  @media only screen and (max-width:800px) {
    .six{
      padding-bottom: 0px;
    }
  }

  /* End of Sec 6 */

  .seven{
    flex-direction: column;
    
  }

  .newletter
  {
    /* height: 60%; */
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px;
    margin-top: 100px;
    margin-bottom: 100px;
    
  }

  .outnews1{
    
    position: relative;
    width: 80%;
    height: 300px;
    border-radius: 100px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    background-color: white;
    padding: 40px;
  }

  .newsletterstylish{
    position: absolute;
    top: -30px;
    right: -30px;
    z-index: -20 ;
  }

  .newsoutbod
  {
    position: absolute;
    background-color: #F7CD61;
    height: 175px;
    width: 175px;
    left: -30px;
    bottom: -30px;
    z-index: -1;
    border-radius: 0px 0px 0px 100px;
   
  }

  .outnewsinner{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 100%;
  }

  .outnewsleft{
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-left: 30px;
  }

  .outnewsleft > h3{
    font-size: 40px;
    margin-bottom: 20px;
  }


  .outinner2{
    height: 100%;
    width: 35%;
    border-radius: 150px 100px 100px 150px;
    background-color: #F7CD61;
    position: relative;
  }

  .subsnow{
    position: absolute;
    height: 80px;
    border-radius: 100px;
    width: 95%;
    min-width: 331px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    top: 50%;
    right: -15%;
    transform: translate(-50%,-50%);
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .subsbtnnews{
    height: 100%;
    width: 40%;
    border-radius: 100px;
    background-color: #435CFF;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .subsbtnnews > span{
    font-size: 18px;
    text-align: center;
    color: white;
    font-weight: 500;
  }

  .subsnow > input{
    border: none;
    font-size: 16px;
    outline: none;
    padding-left: 10px  ;
  }

  .outnewsleft > p{
    color: #757575;
    line-height: 30px;
  }

  .outnewsleft > p > span{
    font-size: 22px;
  }

  @media only screen and (max-width:1320px) {
    .subsnow{
      right: -40%;
    }
  }

  @media only screen and (max-width:1218px) {
    .subsnow{
      right: -50%;
    }

    .outnewsleft > p{
      width: 250px;
    }
  }

  @media only screen and (max-width:1000px) {
    .outnewsinner
    {
      flex-direction: column;
      align-items: center;
    }

    .subsnow{
      position: relative;
      min-width: 270px;
    }

    .outnews1{
      width: 100%;
      border-radius: 50px;
      height: 320px;
    }

    .newletter
    {
      padding: 30px;
      margin-top: 30px;
      margin-bottom: 30px;
    }

    .newsoutbod
  {
   
    left: -10px;
    bottom: -10px;
    border-radius: 0px 0px 0px 50px;
    display: none;
  }

  .outinner2{
    width: 70%;
    margin-top: 30px;
  }

  .subsbtnnews{
    height: 100%;
    width: 40%;
  }

  .subsbtnnews > span{
    font-size: 12px;
    text-align: center;
    color: white;
    font-weight: 500;
  }

  .subsnow > input{
    border: none;
    font-size: 14px;
    outline: none;
  }

  .outnewsleft{
    align-items: center;
    margin-left: 0px;
  }

  .outnewsleft > h3{
    font-size: 33px;

  }

  
  .outnewsleft > p{
    text-align: center;

  }
  }


  

.horiscroller{
    height: 450px;
    /* background-color: #435CFF; */
    display: flex;
    align-items: center;
    margin-top: 40px;
    min-width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    /* overflow-x: hidden; */

}

.horiscroller::-webkit-scrollbar{
  display: none;
}

.featurehoricard
{
  min-width: 550px;
  height: 320px;
  border-radius: 50px;
  padding: 20px;
  background-color: #191641;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-left: 100px;
  margin-right: 100px;
  margin-top: 90px;
}

.featurehoricard > img{
  height: 80%;
  width: auto;
}

.featurehoricard > span{
  font-size: 28px;
  line-height: 50px;
  text-align: center;
}

@media only screen and (max-width:1080px)
{
  .horiscroller{
    margin-top: 0px;
    flex-direction: column;
    height: 100%;
    overflow-x: hidden;
    margin-bottom: 100px;
  }

  .featurehoricard{
    margin-top: 50px;
  }

  .featurehoricard{
    min-width: 460px;
  }
}

@media only screen and (max-width:500px)
{

  .featurehoricard{
    min-width: 350px;
    padding: 10px;
  }

  .featurehoricard > img{
  height: 50%;
  width: auto;
}

.featurehoricard > span{
  font-size: 22px;
  line-height: 30px;

}


}

.landingslider{
  width: 100%;
  /* height: 600px; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

}

.landinnerslider{
  align-items: center;
  justify-content: space-between;
  display: flex;
  min-width: 100vw;
  height: 600px;
  padding-left: 100px;
  padding-right: 100px;
  /* background-color: #757575; */
}

.firstslider{
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 30px;
  background-color: #191641;
  border-radius: 40px;
  min-width: 600px;
  height: 400px;
}

.silderfunctions{
  display: flex;
  align-items: center;
  width: 100vw;
  position: relative;
  transition: right 0.3s ease;
  /* overflow: hidden; */
  /* height: 600px; */
  /* overflow-x: scroll; */
}

.firstslider > img{
  height: 300px;
  width: auto;
}

.firstslider > p{
  font-size: 22px;
}

@media only screen and (max-width:961px) {

.firstslider{
    min-width: 400px;
    height: 300px;
}

.firstslider > img{
  height: 200px;
  width: auto;
}

.firstslider > p{
  font-size: 19px;
}}

@media only screen and (max-width:817px) {
  .landinnerslider{
    align-items: center;
    justify-content: space-between;
    display: flex;
    min-width: 100vw;
    height: 400px;
    padding-left: 30px;
    padding-right: 30px;
  }

  
}

@media only screen and (max-width:560px) {

.landinnerslider{
  padding-left: 0px;
  padding-right: 0px;


}
.firstslider{
  min-width: 250px;
  height: 200px;
}

.firstslider > img{
  height: 130px;
  width: auto;
}

.firstslider > p{
  font-size: 16px;
}

.sec4spltitle{
  margin-top: 80px;
}
}


.sec4spltitle{
  display: flex;
  align-items: center;
  /* justify-content: center; */
  position: relative;
  margin-top: 40px;
}

.sec4splbg{
  width: 95px;
  height: 120px;
  background-color: #F7CD61;
  position: absolute;
  left: 0px;
  border-radius: 50px 0px 0px 0px;
}

.sec4innertitle{
  z-index: 50;
  padding: 20px;
  font-size: 35px;
}

.leftmover{
  margin-left: 15px;
}

@media only screen and (max-width:695px) {
  .sec4innertitle{
    z-index: 50;
    padding: 20px;
    font-size: 25px;
  }

  .sec4splbg{
    width: 77px;
    height: 90px;
    background-color: #F7CD61;
    position: absolute;
    left: 0px;
    border-radius: 50px 0px 0px 0px;
  }
}