.contactpopup{

    padding: 30px;
    width: 450px;
    height: 550px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: white;
    flex-direction: column;
    border-radius: 20px;
    margin-top: 40px;
    position: relative;
    text-align: center;
}

.contactpopup > h3{
    font-size: 24px;
}

.continuecomebtn{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 30px;
    background: #435CFF;
    border-radius: 100px;
    border: none;
    /* width: 300px; */
    /* height: 72px; */
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    text-align: center;
    color: #FFFFFF;
    cursor:pointer;
    
}

@media only screen and (max-width:470px) {
    .contactpopup{
      
        width: 90vw;
        text-align: center ;
    height: 550px;
    /* height: ; */
    }
}