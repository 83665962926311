.maxbeampage{
    /* background-image: url("../../assets/maxbeampagebg.png"); */
    width: 100%;
    padding: 80px;
    display: flex;
    align-items: center;
    justify-content: center;

}

.maxbeaminner{
    display: flex;
    width: 70%;
}

.maxbeaminner > img{
    width: 30%;
    height: auto;
    margin-right: 60px;
}

.maxbeampagedata > span{
    font-size: 50px;
    color: #191641;
}

.maxbeampagedata > p{
    margin-top: 50px;
    width: 90%;
    line-height: 32px;
    font-size: 20px;
    color: rgb(132, 132, 132);
}

.maxbeamspagebtns{
    margin-top: 40px;
    display: flex;
    width: 95%;
    justify-content: space-evenly;
}

.maxbeamsbtn{
    display: flex;
    background-color: #F7CD61;
    padding: 10px 30px;
    border-radius: 30px;
    cursor: pointer;
    align-items: center;
}

.maxbeamsbtn > span{
    margin-left: 10px;
}

@media only screen and (max-width:600px)
{
    .maxbeamsbtn{
        padding: 10px 10px;
    }
}


@media only screen and (max-width:1449px) {
    .maxbeampage{
        padding-top: 80px !important;
        padding: 40px;
    }

    .maxbeaminner{
        width: 80%;
    }
}

@media only screen and (max-width:1186px) {
    .maxbeaminner{
        width: 90%;
    }
}

@media only screen and (max-width:1018px) {

    .maxbeaminner{
        width: 95%;
    }


    .maxbeaminner > img{
        height: 400px;
    }

}

@media only screen and (max-width:960px) {
    .maxbeaminner > img{
        display: none;
    }

    .maxbeampage{
        padding: 20px;
    }

    .maxbeampagedata > p{
        margin-top: 20px !important;
        font-size: 18px !important;
    }

    .maxbeampagedata{
        margin-bottom: 80px;
    }
}