.contactuspage{

    display: flex;
    padding-top: 70px;
}

.marginleft20{
    margin-left: 20px;
}

.marginright20{
    margin-right: 20px;
}

.contactleft{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    padding: 60px;
    background-color: #F7CD61;

}

.contactleftinner{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 600px;
}

@media only screen and (max-width:1421px) {
    .contactleftinner{
        width: 400px;
    }
}

@media only screen and (max-width:890px)
{
    .contactuspage{
        flex-direction: column;
    }

    .contactleft{
        width: 100%;
    }

    .contactright{
        width: 100% !important;
        height: 400px;
    }

    .contactleftinner{
        width: 80%;
    }

    .mapselector{
        bottom: 20px !important;
    }
}

@media only screen and (max-width:607px) {
    
    .contactleft{
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 40px !important;
        padding-bottom: 20px;
    }

    .contactleftinner{
        width: 100%;
    }

    .contactlsec4{
        padding-left: 20px;
        padding-right: 20px;
        flex-direction: column;
    }

    .contactlsec4inner{
        width: 230px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .contactlsec4innerdata2{
        font-size: 16px;
    }

    .contacticon
    {
        margin-right: 10px !important;
        margin-top: 10px !important;
    }

    .mapselector{
        width: 250px !important;
    }
}

.contactlsec1{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}


.contactlsec1inner
{
    width: 100%;
    display: flex;
    flex-direction: column;

}

.contactlsec1inner > label{
    margin-bottom: 20px;
    font-weight: 600;
}

.contactlsec1inner > input{
    padding: 15px;
    border: none;
    outline: none;
    border-radius: 10px;
}

.contactleftinner > h1{
    font-size: 45px;
    margin-bottom: 20px;
}

.contactleftinner > p{

}




.contactlsec2{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.contactlsec2 > label{
    margin-bottom: 20px;
    font-weight: 600;
}

.contactlsec2 > input{
    padding: 15px;
    border: none;
    outline: none;
    border-radius: 10px;
    margin-top: 10px;
}

.contactlsec3{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    position: relative;
}

.textlimiter{
    position: absolute;
    right: 15px;
    bottom: 15px;
    color: rgb(123, 123, 123);
    font-size: 14px;
    transition: all 0.3 ease;
}

.textlimitererror{
    color: red;
    font-size: 18px;

}

.contactlsec3 > label{
    margin-bottom: 20px;
    font-weight: 600;
}

.contactlsec3 > textarea{
    padding: 15px;
    border: none;
    outline: none;
    border-radius: 10px;
    height: 200px;
    margin-top: 10px;
}

.contactusmsgbtn{
    width: 100%;
    background-color: #435CFF;
    color: white;
    padding: 15px;
    border-radius: 40px;
    border: none;
    margin-bottom: 40px;
    font-size: 22px;
}
.contactlsec4{
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
}

.contactlsec4inner{
    display: flex;
    align-items: center;
}

.contactlsec4innerdata{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
}

.contacticon{
    padding: 10px;
    border-radius: 50%;
    background-color: #435CFF;
    color: white;
    margin-right: 20px;
    cursor: pointer;
}

@media only screen and (max-width:600px) {
    .contacticon{
        padding: 15px;
        
    }
}

.contactlsec4innerdata2{
    font-weight: 600;
    /* margin-top: 10px; */
}

.contactright{
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;

}

.mapselector{
    position: absolute;
    bottom: 100px;
    background-color: white;
    width: 400px;
    height: 100px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.mapselectordrop{
    width: 100%;
    display: flex;
    height: 100%;
    align-items: center;
}

.mapselectordrop select{
    width: 100%;
    font-size: 22px;
    border: none;
    outline: none;
}