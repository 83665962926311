.productspage{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow-x: hidden;
}

.prodsec1{
    width: 100%;
    height: 100vh;
    padding: 120px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../../assets/productslandbg.png");
    display: flex;
    flex-direction: column;
    /* align-items: flex-start; */
    justify-content: center;
}

.prodsec1 h1{
    color: white;
    font-size: 70px;
    margin-bottom: 200px;
}

.prodsec1 span{
    color: #191641;
    background-color: white;
    padding: 30px;
    font-style: italic;
    font-size: 50px;
    border-radius: 20px;
    align-self: center;
}

@media only screen and (max-width:1085px) {
    
}

.prodsec1{
    padding: 40px;
}

@media only screen and (max-width:920px) {
    .prodsec1{
    padding: 20px;
}

.prodsec1 h1{
    font-size: 50px;
    margin-bottom: 50px;
    /* background-color: black; */
    padding: 10px;
    border-radius: 10px;
    text-shadow: -4px -4px 0 #000, 4px -4px 0 #000, -4px 4px 0 #000, 4px 4px 0 #000;
}

.prodsec1 span{
    font-size: 30px;
    text-align: center  ;
}

}



.prodsec3{

    width: 100%;
    padding: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    background-image: url("../../assets/productsec2bg.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 500px;

}

.prodsec3bg2{
    background-image: url("../../assets/productsec3bg.png");

}

.prodsec3 h2{
    text-align: center;
    width: 60%;
    margin: 30px;
    font-size: 50px;
}

@media only screen and (max-width:893px) {

    .prodsec3{
        padding: 20px;
        height: 300px;

    }

    .prodsec3 h2{
        margin: 0px;
        font-size: 30px;
        width: 100%;
    }
}

.prodsec3 > button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 30px;
    background: #435CFF;
    border-radius: 100px;
    border: none;
    width: 300px;
    height: 72px;
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    text-align: center;
    color: #FFFFFF;
    cursor:pointer;
    margin-top: 10px;
}

.prodsec4{
    padding-top: 100px;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
   
}

.prodsec4data{
    padding: 110px;
    display: flex;
    flex-direction: column;
}

.prodsec4data h1{
    font-size: 60px;
}

.prodsec4liner{
    width: 300px;
    height: 3px;
    background-color: #435CFF;
    margin-top: 20px;
    margin-bottom: 50px;
}

.prodsec4data p{
    font-size: 20px;
    line-height: 40px;
}

.prodsec4data button{
    margin-top: 60px;
    background: #435CFF;
    padding: 15px 45px;
    border-radius: 100px;
    color: white;
    border: none;
    width: 320px;
    align-self: center;
    font-size: 22px;
}

.prodsec4img{
    width: 350px;
    height: auto;
    position: absolute;
    right: 100px;
    top: 100px;
}


@media only screen and (max-width:944px) {
    .prodsec4img{
    width: 250px;
    height: auto;
}

.prodsec4data p{
    font-size: 18px;
    line-height: 35px;
}
}

@media only screen and (max-width:800px) {
 .prodsec4data h1{
    font-size: 50px;
}

.prodsec4data{
    padding-left: 40px;
    padding-right: 40px;
}

.prodsec4img{
    width: 200px;
    height: auto;
    position: absolute;
    right: 30px;
    top: 150px;
}
}

@media only screen and (max-width:600px) {
 .prodsec4data h1{
    font-size: 40px;
    margin-top: 20px;
}

.prodsec4data{
    padding-left: 40px;
    padding-right: 40px;
}

.prodsec4img{
    width: 150px;
    height: auto;
    position: absolute;
    right: -20px;
    top: 210px;
    transform: rotateZ(-30deg);
}
}

.prodsec5{
    padding-top: 100px;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: #F7CD61;
   
}

.prodsec5data{
    padding: 110px;
    display: flex;
    flex-direction: column;
}

.prodsec5data h1{
    font-size: 60px;
}

.prodsec5liner{
    width: 300px;
    height: 3px;
    background-color: #435CFF;
    margin-top: 20px;
    margin-bottom: 50px;
}

.prodsec5data p{
    font-size: 20px;
    line-height: 40px;
}

.prodsec5data button{
    margin-top: 60px;
    background: #435CFF;
    padding: 15px 45px;
    border-radius: 100px;
    color: white;
    border: none;
    width: 320px;
    align-self: center;
    font-size: 22px;
}

.prodsec5img{
    width: 350px;
    height: auto;
    position: absolute;
    right: 100px;
    top: 100px;
}

@media only screen and (max-width:944px) {
    .prodsec5img{
    width: 250px;
    height: auto;
}

.prodsec5data p{
    font-size: 18px;
    line-height: 35px;
}
}

@media only screen and (max-width:800px) {
 .prodsec5data h1{
    font-size: 50px;
}

.prodsec5data{
    padding-left: 40px;
    padding-right: 40px;
}

.prodsec5img{
    width: 200px;
    height: auto;
    position: absolute;
    right: 30px;
    top: 150px;
}
}

@media only screen and (max-width:600px) {
 .prodsec5data h1{
    font-size: 30px;
    margin-top: 50px;
}

.prodsec5data{
    padding-left: 40px;
    padding-right: 40px;
}

.prodsec5img{
    width: 150px;
    height: auto;
    position: absolute;
    right: -20px;
    top: 190px;
    transform: rotateZ(-30deg);
}
}

.prodsec6{
    width: 100%;
    padding-top: 100px;
    background-color: #FAFAFA;
    padding-bottom: 100px;
}

.jumpedletterimg{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.jumpedletterimg img{
    margin-top: 100px;
    margin-bottom: 100px;
    width: 80%;
    height: auto;
}

@media screen and (max-width:600px) {
    .jumpedletterimg img{
        width: 90%;
        height: auto;
        content:url("../../assets/responsiveprod.png");
        margin-top: 80px;
    }
}

.prodsec7{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 100px;
}

.prodsec7inner{
    width: 100%;
    justify-content: center;
    padding: 100px;
    display: flex;
    align-items: center;
}

.testicard{
    height: 350px;
    width: 700px;
    background-color: #F7CD61;
    border-radius: 20px;
    position: relative;
}

.prodsec7inner > h1{
    margin-right: 100px;
    font-size: 56px;
}

.testicard{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
}

.testicard img{
    height: 100%;
    width: auto;
}

.testicardinner
{
    padding: 40px;
 
}

.testicardinner h6{
    margin-top: 20px;
    font-size: 18px;
}

.testicardinner > img{
    width: 35px;
    height: auto;
}

.testicardinner > p{
    margin-top: 10px;
}

.protestiwaves{
    position: absolute;
    width: 100%;
    height: auto;
    z-index: -50;
}

.protestiright{
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media only screen and (max-width:1160px) {
    .prodsec7inner > h1{
        font-size: 46px;
    }

    .prodsec7inner{
        padding-left: 30px;
        padding-right: 30px;
    }
}

@media only screen and (max-width:1100px) {
    .prodsec7inner{
        flex-direction: column;
    }

    .prodsec7inner > h1{
        margin-right: 0px;
        margin-bottom: 50px;
    }
}

@media only screen and (max-width:750px) {
    .testicard{
        flex-direction: column;
        height: 100%;
        width: 100%;
    }

    .testicardinner{
        padding: 10px;
    }
}

@media only screen and (max-width:1260px) {
    .prodsec7inner > h1{
            font-size: 46px !important;
    }
}

.secprodlabel{
    max-width: 400px;
    width: 35%;
    min-width: 260px;
    background-color: #191641;
    padding: 25px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    /* margin-bottom: 30px; */
   }