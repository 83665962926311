@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');

.Userprofile{
    padding-top: 150px;
    padding-left: 80px;

    padding-bottom: 120px;
    display: flex;
}

.userprofinner{
    display: flex;
    width: 100%;
}

.profileleft{
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    
}

.profileleft img{
    border-radius: 50%;
    width: 200px;
    height: 200px;
    cursor: pointer;
}

.proftag{
    background-color: #435CFF;
    height: 30px;
    width: 30px;
    position: absolute;
    bottom: 0px;
    right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    cursor: pointer;

}

.profitems{
    display: flex;
    flex-direction: column;
}

.profitems > label{
    margin-top: 30px;
    margin-bottom: 15px;
    font-weight: 400;
    font-size: 21px;
}

.profitems input{
    border: 1px solid rgb(217, 217, 217);
    height: 40px;
    width: 400px;
    border-radius: 5px;
    outline: none;
    padding: 10px;
    font-size: 18px;
}

.changepassbtn{
    width: 400px;
    height: 40px;
    border-radius: 100px;
    outline: none;
    margin-top: 40px;
    margin-bottom: 10px;
    border: none;
    background-color: #435CFF;
    color: white;
    cursor: pointer;
    font-size: 18px;

}

.profileoptns{
    display: flex;
    width: 400px;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 40px;
}

.profileoptns > button,.profileoptns > input {
    cursor: pointer;
    padding: 10px 35px 10px 35px;
    border-radius: 100px;
    font-size: 19px;
}

.cancelbtn{
    border: 2px solid black;
    border-radius: 100px;
    background-color: white;
}

.savbtn{
    border: none;
    background-color: #435CFF;
    color: white;
}

.profileright{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 80%;
}



.profrightdata{
    width: 90%;
    background-color: #F7CD61;
    padding: 40px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    text-align: center;
    border-radius: 20px;
    
}

.profrightdata > span{
    font-size: 42px;
    font-weight: 600;
    margin-bottom: 10px;
    margin-top: 10px;
    font-family: 'Pacifico', cursive;
}

.profrightdata > p{
    font-size: 22px;
    margin: 4px;

}

.containerclass{
    width: 500px;
    font-size: 18px;
}

@media only screen and (max-width:1413px)
{
    .profileleft{
        width: 30%;
    }

    .profileright{
        width: 70%;
    }
}

@media only screen and (max-width:1115px)
{
    .profileleft{
        width: 40%;
    }

    .profileright{
        width: 60%;
    }

    .profrightdata > span{
        font-size: 32px;
        font-weight: 600;
        margin-bottom: 10px;
        margin-top: 10px;
        font-family: 'Pacifico', cursive;
    }
    
    .profrightdata > p{
        font-size: 18px;
        margin: 4px;
    
    }
}

@media only screen and (max-width:941px)
{
    .Userprofile{
        /* width: 100%; */
        padding-left: 0px;
        padding-bottom: 30px;
    }

    .profileleft{
        width: 100%;
    }

    .profileright{
        display: none;
    }

    .profrightdata > span{
        font-size: 32px;
        font-weight: 600;
        margin-bottom: 10px;
        margin-top: 10px;
        font-family: 'Pacifico', cursive;
    }
    
    .profrightdata > p{
        font-size: 18px;
        margin: 4px;
    
    }
}

@media only screen and (max-width:450px)
{
    .profileoptns{
        width: 300px;
    }
    .profitems input{
        width: 90vw;
    }

    .changepassbtn{
        width: 90vw;

    }

    .profileleft img{
        border-radius: 50%;
        width: 200px;
        height: 200px;
        cursor: pointer;
    }

    .containerclass{
        width: 200px;
    }
}