.navbar{
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    padding-left: 80px;
    padding-right: 80px;
    justify-content: space-between;
    /* background: rgba( 255, 255, 255, 0.1 ); */
    /* box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 ); */
    /* backdrop-filter: blur( 1px );
    -webkit-backdrop-filter: blur( 1px ); */
    /* border: 1px solid rgba( 255, 255, 255, 0.18 ); */
    position: fixed;
    z-index: 10;
}

.opmock{
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: #435CFF;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.navbar h1{
    font-size: 26px;
}

.sidenav{
    position: fixed;
    height: 100vh;
    width: 350px;
    right: 0px;
    top: 0px;
    background-color: white;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    transition: all 0.5s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
    /* display: none; */
}

.disnone{
    right: -350px;

}

.opmocksidenav{
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: #435CFF;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    right: 30px;
    top: 20px;
}

.sidenavlinks{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 50%;
    justify-content: space-evenly;
}

.sidenavlinks span{
    font-size: 24px;
    cursor: pointer;
}

.spanpadding{
    padding: 10px;
    background-color: #435CFF;
    color: white;
    border-radius: 100px;
    width: 200px;
    text-align: center;
}

.spanborder{
    border-radius: 100px;
    border: 2px solid #435CFF;
    padding: 10px;
    width: 200px;
    text-align: center;

}

@media only screen and (max-width:1050px) {
    .navbar{
        padding-left: 40px;
        padding-right: 40px;
    }
}

@media only screen and (max-width:700px) {
    .navbar{
        padding-left: 20px;
        padding-right: 20px;
    }

    .sidenav{
        width: 300px;
    }

    .sidenavlinks span{
        font-size: 20px
    }

    .sidenavlinks{
        margin-bottom: 50px;
    }
}