.minibeamscardcont{
    height: 300px;
    min-width: 500px;
    background-image: url('../../assets/microbeamsbg.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 5px;
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    text-align: center;
    margin-left: 30px;
    margin-Right: 30px;
    margin-top: 40px;
    margin-bottom: 40px;
    position: relative;
}

.minibeamscardcontfav{
    width: 500px;
    height: 250px;
    background-image: url('../../assets/microbeamsbg.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 5px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    text-align: center;
   
    margin: 10px;
    margin-bottom: 60px;
    position: relative;
}

.minibeamsinnerfav{
    height: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    text-align: center;
    position: absolute;
    width: 300px;
    right: 30px;
}

@media only screen and (max-width:537px)
{
    .minibeamscardcontfav{
        width: 95vw;
    }
}

@media only screen and (max-width:1280px) {
    .minibeamscardcont{
        margin-left: 10px;
        margin-Right: 10px;
    }
}




@media only screen and (max-width:600px)
{

    .minicardssection{
        width: 100%;
        padding: 0px;

    }

    .minibeamscardcont{
        min-width: 85vw !important;
        height: 270px;
        background-image: url('../../assets//Popupbg.png');
        
    }

    .minibeamsinner{
        left: 50%;
        transform: translateX(-50%);
    }

   
}

.minibeamsinner > h3, .minibeamsinnerfav >h3{
    font-size: 22px;
    color: #F7CD61;
}

.minibeamsinner > p, .minibeamsinnerfav >p{
    color: white;
    line-height: 30px;
}


.minibeamsinner > button, .minibeamsinnerfav > button{
    width: 120px;
    height: 41.5px;
    background: #F7CD61;
    border-radius: 30px;
    border: none;
    font-weight: 600;
    /* font-size: 18px; */
    cursor: pointer;
}

.minibeamsinner{
    height: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    text-align: center;
    position: absolute;
    width: 300px;
    right: 30px;
}


.minicontrols{
    position: absolute;
    bottom: 15px;
    right: 15px;
    color: white;
}

.opdp{
    position: absolute;
    width: 200px;
    height: 150px;
    background-color: white; 
    bottom: 60px;
    right: -40px;
    border-radius: 20px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 12px;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    display: none;
    z-index: 25;
}

@media only screen and (max-width:450px) {
    .opdp{
    right:  0px;

    }
}

.opdpitems{
    display: flex;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 7px;
    border-radius: 20px;
    cursor: pointer;
}

.opdpdisplay{
    display: flex;
    padding-top: 20px;
}

.opdpitems:hover{   
    background-color: #f3f3f3;
}



.closeminicard{
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
}

.hightobjs{
    color: black;
}