.beamspage{
    width: 100%;
    background-color: #FAFAFA;
    display: flex;
    flex-direction: column;
    position: relative;
}

.trendingbeamshero{
    background-image: url("../../assets/beamsherobg.png");
    height: 450px;
    width: 100%;
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 40px;
    position: relative;

}

.minibeamsbg{
    
    background-image: url("../../assets/microbeamsbannerbg.png");
}

.microbeambg{
    
    background-image: url("../../assets/minibeamsbannerbg.png");
}

@media only screen and (max-width:935px) {
    .trendingbeamshero > h1{
        font-size: 42px;
    }
}

@media only screen and (max-width:700px) {
    .trendingbeamshero > h1{
        font-size: 36px;
    }
    .heroimgbeam{
        height: auto;
        width: 300px    ;
    }
}

@media only screen and (max-width:600px) {
    .trendingbeamshero > h1{
        font-size: 30px;
    }

    .heroimgbeam{
        height: auto;
        width: 300px;
        bottom: -60px !important; 
    }
}

@media only screen and (max-width:600px) {
    .trendingbeamshero > h1{
        font-size: 30px;
        text-align: center !important;
        line-height: 50px;
    }

    .heroimgbeam{
        height: auto;
        width: 300px;
        bottom: -60px !important; 
       
    }
}

@media only screen and (max-width:450px) {
    .trendingbeamshero > h1{
        font-size: 28px;
        text-align: center !important;
        line-height: 50px;
    }

    .heroimgbeam{
        height: auto;
        width: 250px;
        bottom: -60px !important; 
       
    }

    .minibeams > span{
        margin-right: 25px !important; 
        /* margin-top: -25px !important; */
        color: rgb(112, 112, 112) !important;
    }
}




.minibeams{
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.minicardcont{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

/* .minicardssection{
    max-width: 1400px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    padding-left: 80px;
    padding-right: 80px;
    margin-top: 30px;
    position: relative;
} */

.minicardssection{
    padding-left: 80px;
    padding-right: 80px;
    margin-top: 30px;
    position: relative;
    max-width: 1400px;  
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
    /* grid-auto-flow: column; */
    justify-items: center;
    align-items: center;  
    justify-content: center;
}

@media only screen and (max-width:1280px) {
    .minicardssection{
        padding-left: 20px;
        padding-right: 20px;
    }
}

@media only screen and (max-width:1072px) {
    .minicardssection{
        max-width:unset;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));

    }

}

/* @media only screen and (max-width:528px) {
    .minicardssection{
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));

    }
} */

.minibeams > span{
    align-self: flex-end;
    margin-right: 170px;
    margin-top: 0px;
    color: black;
    font-weight: 500;
    font-size: 18px;    
    cursor: pointer;
}

.minibeams > span:hover{
 
    color: #1837ff;
  
}

.backdrop{
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(120, 120, 120, 0.8);
    display: flex;
    align-items: center;    
    justify-content: center;
    z-index: 100;
}

.popupcloser{
    height: 5%;
    width: 100%;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    
}

.popup{
    width: clamp(60%,1000px,95%);
    height: min(70%,900px);
    margin: auto;
    background-color: white;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    position: relative;

}

@media only screen and (max-width:700px) {
    .popup{
        height: 300px !important;
    }

    .popupdata{
        padding: 10px !important;
    }

    .popupdata > h2{
   
        font-size: 30px !important;
        text-align: center;
    }
    
    .popdatainner > span{
        font-size: 20px !important;
        line-height: 35px !important;
    }

    .popdatainner > img{
        width: 25% !important;

    }

    .popupicons > img{
        margin-bottom: 15px !important;
        height: 30px;
        width: 30px;
    }

    .popupicons{
        justify-content: space-evenly;
        align-items: center;
    }
}

.popupdata{
    background-image: url('../../assets//Popupbg.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    width: 100%;
    padding: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 20px 20px 20px 20px;
    
}

.popdatainner{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.popupdata > h2{
    color: #F7CD61;
    font-size: 55px;
}

.popdatainner > span{
    color: white;
    font-size: 40px;
    text-align: center;
    padding: 20px;
    line-height: 60px;
    
}

.popdatainner > img{
    width: 35%;
    height: auto;
}

.heroimgbeam{
    position: absolute;
    bottom: -90px;
    transform: rotateY(20deg);
    z-index: 10;
}

.innernav{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 100px;
    padding-left: 50px;
    padding-right: 50px;
}

.innernav > span{
    font-size: 26px;
}


.popupicons{
    display: flex;
    flex-direction: column;

}

.popupicons > img{
    cursor: pointer;
    margin-bottom: 40px;
}

@media only screen and (max-width:1014px) {
    .innernav{
        margin-top: 110px;
        padding-left: 10px;
        padding-right: 10px;
    }
    .innernav > span{
        font-size: 21px;
    }

    .searchbar > input{
        width: 200px !important;
    }

   
}

@media only screen and (max-width:450px)
{
    .searchbar > input{
        width: 180px !important;
    }

    .innernav > span{
        font-size: 18px;
    }
}

.minicardsnaver{
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;

}   

.minicardcontscroller{
   width: 100%;
   position: relative;
   display: flex;
   align-items: center;
   justify-content: center;
   margin-top: 30px;
   margin-bottom: 5px;
}

.minicardssectionscroller{

    /* right: 100px; */
    display: flex;
    overflow-x: hidden;
   width: 1120px;
}

.minicardssectionscroller1{
    position: relative;
    display: flex;
    transition: right 0.3s ease;
   
}

.minicardssectionscroller::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .minicardssectionscroller {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  .mover{
  
}

@media only screen and (max-width:1178px) {
    .sliderarrows{
        display: none;
    }

    .minicardssectionscroller{

        /* right: 100px; */
        display: flex;
        /* flex-direction: column; */
        width: 100%;
        align-items: center;
        justify-content: center;

    }
    
    .minicardssectionscroller1{
        position: relative;
        display: flex;
        transition: right 0.3s ease;
        width: 100%;
        /* flex-direction: column; */
        flex-wrap: wrap ;
        align-items: center;
        justify-content: center;
    }

    .minicardcontscroller{
        margin-bottom: 0px;
    }
}

.searchPage{
    display: flex;
    flex-direction: column;
    padding-left: 80px;
    padding-right: 80px;
}

@media only screen and (max-width:600px)
{
    .searchPage{
        padding-left: 0px;
        padding-right: 0px;
    }

    .searchPageheading{
        margin-left: 20px;
    }

    .searchpageleftoptions{
        margin-left: 20px;
        margin-right: 20px;
    }

    .searchpageleftoptions > span{
        font-size: 15px !important;
    }
}

.searchPageheading{
    margin-top: 50px;
    font-size: 34px;
    font-weight: 500;
}

.searchPageoptions{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 35px;
}

.searchpageleftoptions{
    display: flex;
    width: 500px;

    justify-content: space-between;
}

.searchpageleftoptions > span{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 23px 15px 23px;
    border-radius: 30px;
    font-weight: 500;
    font-size: 19px;
    cursor: pointer;
}


.suggestionlist{
    width: 261px;
    max-height: 200px;
    /* height: 200px; */
    background-color: #ffffff;
    position: absolute;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    margin-top: 20px;
    border-radius: 20px;
    /* padding: 20px; */
    overflow-y: scroll;
}

@media only screen and (max-width:1015px) {
    .suggestionlist{
        right: 5px;
        width: 230px;
    }
}

.suggestionlist::-webkit-scrollbar {
    width: 20px;
  }

.suggestionlist::-webkit-scrollbar-track {
    background-color: transparent;
  }


  .suggestionlist::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
  }

  .suggestionlist::-webkit-scrollbar-thumb:hover {
    background-color: #c2c2c2;
  }

.suggestionlist > p{
    padding: 10px;
    cursor: pointer;
    border-radius: 20px;
    
}

.suggestionlist > p:hover{
    background-color: rgb(246, 246, 246);
}