/* .maxbeamcardcont{
    display: flex;
    width: 500px;
    height: 250px;
    background-color: white;
    box-shadow: 0px 20px 60px rgba(128, 144, 155, 0.2);
    border-radius: 20px;
    margin: 30px;
    
} */


.maxbeamcardcontnew{
    display: flex;
    width: 500px;
    height: 300px;
    background-color: white;
    box-shadow: 0px 20px 60px rgba(204, 214, 220, 0.2);
    border-radius: 5px;
    margin-left: 30px;
    margin-Right: 30px;
    margin-top: 40px;
    margin-bottom: 40px;
    position: relative;
}

@media only screen and (max-width:600px)
{
    .maxbeamcardcontnew{
        height: 250px;
        width: 85vw;
    }


    
}

.maxbeamdata > button{
    width: 120px;
    height: 41.5px;
    background: #F7CD61;
    border-radius: 30px;
    border: none;
    font-weight: 600;
    /* font-size: 18px; */
    cursor: pointer;
}


.maxbeamdata{
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    text-align: center;
    width: 75%;
}

.maxcardimgnew{
    
    width: 35%;
}
.maxcardimgnew img{
    height: 100%;
    width: 100%;
    border-radius: 5px 0px 0px 5px;
}