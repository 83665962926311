
@import url('https://fonts.googleapis.com/css2?family=Roboto&family=Ubuntu:ital,wght@0,300;0,400;0,500;1,500;1,700&display=swap');

.maxbeamsreadpage{
    padding-top: 20px;
    position: relative;
}

.maxbeamsreadheader{
    height: 70px;
    background-color: #435CFF;
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-top: 35px;
}

.maxbeamsreadheader h2{
    font-size: 39px;
    color: white;
}

.readtext{
    width: 900px;
    margin-top:  50px;
    margin-bottom: 100px;

}

@media only screen and (max-width:980px) {
    .readtext{
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
    }

    .readtext p{
        font-size: 18px !important;
    }

    .maxbeamsreadheader h2{
        font-size: 28px;
    }

    .innernav > span{
        width: 80%;
    }
}

.readtext > h3{
    color: #435CFF;
    margin-top: 50px;
    margin-bottom: -30px;
}

.readtext > p{
    font-size: 20px;
    font-family: 'Roboto', sans-serif;
    line-height: 40px;
    margin-top: 30px;
}

.YourHighlightClass{
    font-size: 20px;
    font-family: 'Roboto', sans-serif;
    line-height: 40px;
}

.highlightop{
    position: absolute;
    bottom: 0px;
    right: 0px;
    z-index: 10;
    width: 190px;
    height: 80px;
    background-color: white;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.highlightopinner{
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    padding: 10px;
    width: 40%;
    cursor: pointer;
}

.highlightopinner:hover{
    background-color: rgb(243, 243, 243);
}

.highlightopinner  span{
    margin-top: 10px;
    font-size: 14px;
}

.hightobjs{
    background-color: rgb(249, 211, 113, 0.6);
    border-radius: 1px;
  
}

.settingdropdown{
    right: 40px;
    top: 100px;
    position: fixed;
   
}

.settingdropdownbg
{
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    background-color: white;
}

.settingdrops{
    width: 300px;
    height: 200px;
    background-color: white;
    border-radius: 30px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    position: absolute;
    right: -30px;
    top: 50px;
    padding: 15px;
}

.settingsrops1{
    margin-top: 6px;
}

.settingsrops1 > div{
    /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
    /* padding: 5px; */
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 15px;
}

.settingsrops1 > label{
    font-weight: 500;

}